@font-face 
{
    font-family: 'pt_sans';
    font-weight: 400;
    font-style: normal;

    src: url('/static/site_templates/crm_interface_v2/fonts/ptsans-regular-webfont.eot');
    src: url('/static/site_templates/crm_interface_v2/fonts/ptsans-regular-webfont.eot?#iefix') format('embedded-opentype'),
    url('/static/site_templates/crm_interface_v2/fonts/ptsans-regular-webfont.woff2') format('woff2'),
    url('/static/site_templates/crm_interface_v2/fonts/ptsans-regular-webfont.woff') format('woff'),
    url('/static/site_templates/crm_interface_v2/fonts/ptsans-regular-webfont.ttf') format('truetype');
}
@font-face 
{
    font-family: 'pt_sans';
    font-weight: 700;
    font-style: normal;

    src: url('/static/site_templates/crm_interface_v2/fonts/ptsans-bold-webfont.eot');
    src: url('/static/site_templates/crm_interface_v2/fonts/ptsans-bold-webfont.eot?#iefix') format('embedded-opentype'),
    url('/static/site_templates/crm_interface_v2/fonts/ptsans-bold-webfont.woff2') format('woff2'),
    url('/static/site_templates/crm_interface_v2/fonts/ptsans-bold-webfont.woff') format('woff'),
    url('/static/site_templates/crm_interface_v2/fonts/ptsans-bold-webfont.ttf') format('truetype');
}

* {
    -webkit-box-sizing: border-box;
       -moz-box-sizing: border-box;
            box-sizing: border-box;
    margin: 0;
    padding: 0;
}

html 
{
    overflow-x: hidden;
    width: 100%;
    max-width: 100%;
    font-family: 'pt_sans', sans-serif;
}

body 
{
    position: relative;
    font-family: 'pt_sans', sans-serif;
    font-size: 14px;
    width: 100%;
    max-width: 100%;
}
@media all and (max-height: 820px) 
{
    body 
    {
        font-size: 12px;
    }
}
@media all and (max-width: 700px) and (orientation: portrait) 
{
    body 
    {
        font-size: 4vw;
    }
}
@media all and (max-width: 700px) and (orientation: landscape) 
{
    body 
    {
        font-size: 4vh;
    }
}

/* Основные блоки шаблона приложения */
.app-wrap
{
    width: 100vw;
    min-height: 100vh;
    background-color: #fff;
    padding: 0 0 0 100px;
    z-index: 1;
    
    @media all and (max-width: 700px) 
    {
        padding-left: 0;
        padding-top: 4.2em;
    }

    .app-sidebar
    {
        position: fixed;
        height: 100%;
        width: 100px;
        top: 0;
        left: 0;
        background-color: #2B2B2B;
        z-index: 2;

        @media all and (max-width: 700px) 
        {
            width: 100%;
            height: 4.2em;
        }
    }

    .app-content
    {
        position: relative;
        display: block;
        z-index: 1;
        min-height: 100vh;
        
        @media all and (max-width: 700px) 
        {
            min-height: calc(100vh - 4.2em);
            width: 100%;
        }
    }
}

/* Основные блоки шаблона экрана авторизации */
.app-auth 
{
    width: 100vw;
    min-height: 100vh;
    background-color: #fff;
    background-image: url('../imgs/auth/bgr_total.png');
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-position: center;
    background-size: auto 75%;
    z-index: 1;
}

/* Стандартная Ajax-иконка */
.default-ajax-loader
{
    position: fixed;
    display: block;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0.5;
    visibility: visible;
    // background-color: #fff;
    background-image: url('/static/site_templates/crm_interface_v2/imgs/ajax_loaders/loader.svg');
    background-repeat: no-repeat;
    background-position: 50% 50%;
    transition: opacity 0.3s ease-out, visibility 0.3s;
    z-index: 1000;
}
.default-ajax-loader.hidden { display: block; visibility: hidden; opacity: 0; }

/*************************************/
/* СТАНДАРТНЫЙ СТИЛЬ КНОПОК В ФОРМАХ */
/*************************************/
.defaultButtonUIGrey 
{
	display: inline-block;
	color: #fff;
    font-size: 1em;
	font-weight: bold;
    text-decoration: none;
    line-height: 1;
	padding: 0.9em 1.1em;
	margin: 0.3em;
	cursor: pointer;
    border-radius: 0;
    border: none;
    background-color: #000000; 
    transition: background-color 0.5s;
}
.defaultButtonUIGrey:link,
.defaultButtonUIGrey:active,
.defaultButtonUIGrey:visited { background-color: #000000; color: #fff; border: none; }
.defaultButtonUIGrey:hover { background-color: #313131; color: #fff; border: none; }
.defaultButtonUIGrey span { display: inline-block; margin-right: 10px; }

.defaultButtonUI 
{
	display: inline-block;
	color: #fff;
    font-size: 1em;
	font-weight: bold;
	text-decoration: none;
    line-height: 1;
    padding: 0.9em 1.1em;
	margin: 0.3em;
    cursor: pointer;
    border-radius: 0;
    border: none;    
    background-color: #FF0000; 
    transition: background-color 0.5s;
}
.defaultButtonUI:link,
.defaultButtonUI:active,
.defaultButtonUI:visited { background-color: #FF0000; border: none; }
.defaultButtonUI:hover { background-color: #FF5942; border: none; }
.defaultButtonUI:disabled:hover { background-color: #FF5942; border: none; }
.defaultButtonUI span { display: inline-block; margin-right: 10px; }

.defaultButtonUI-black { background-color: #000000; }
.defaultButtonUI-black:active,
.defaultButtonUI-black:visited { background-color: #000000; color: #fff; border: none; }
.defaultButtonUI-black:hover { background-color: #313131; color: #fff; border: none; }
.defaultButtonUI-black:disabled:hover { background-color: #000000; color: #fff; border: none; }

.defaultButtonUI-grey { background-color: #abaaaa; }
.defaultButtonUI-grey:disabled:hover { background-color: #abaaaa; }

.defaultButtonUI:disabled { opacity: 0.3; }

/*************************************/
/* СТАНДАРТНЫЕ СТИЛИ ДИАЛОГОВЫХ ОКОН */
/* Стандартное окно с сообщениями */
.dialogMessageBlock { position: relative; z-index: 10; margin: 10px 0 25px 0; }
.dialogButtonsBlock { text-align: center; position: relative; z-index: 20; }
.dialogButton 
{
	display: inline-block;
	color: #fff;
    font-size: 1em;
	font-weight: bold;
    text-decoration: none;
	padding: 0.9em 1.1em;
	margin: 0.3em;
	line-height: 1;
	cursor: pointer;
    border-radius: 0;
    border: none;    
    background-color: #FF0000; 
    transition: background-color 0.5s;
}
.dialogButton:link,
.dialogButton:active,
.dialogButton:visited { background-color: #FF0000; border: none; }
.dialogButton:hover { background-color: #FF5942; border: none; }
